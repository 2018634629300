.App {
  text-align: center;
}

 .cart-trash,.cart-plus{
  font-size: 13px!important;
 }
 .mini-counter{
  position: absolute; top: -5px; right: 25px; font-size: 12px; font-weight: bold; background-color: rgb(252, 252, 252); color: rgb(0, 0, 0); border: 1px solid; border-radius: 25px; height: 24px; width: 24px; display: flex; align-items: center; justify-content: center; z-index: 1;
 }
 .checkout-cart .cart-item:first-child{
  border-top-width: 0px!important;
 }
 /* width */
::-webkit-scrollbar {
  width: 15px;
 
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #FFCB04; 
  border-radius: 10px; cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ffd21e; 
}

.order-items::-webkit-scrollbar {
  width: 5px;
 
}
.checkout-cart::-webkit-scrollbar ,.sidebar-cart::-webkit-scrollbar{
  width: 10px;
 
}
.trim-2{
  overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
}
.admin-main::-webkit-scrollbar {
  display: none;
}
.bg-red-200 {
  background: #ff8c84;
}
/* Hide scrollbar for IE, Edge and Firefox */
.admin-main {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}